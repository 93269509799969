
export default function () {
    const selectElement = document.querySelector('#newsletter-month-select');

    if (!selectElement) {
        return;
    }

    selectElement.addEventListener('change', function () {
        window.location.href = this.value;
    });
}
